import { getSuppliersAPI,  createSupplierAPI, updateSupplierAPI } from "@api/suppliers";
import { APIError } from "@ctypes/common";
import { SuppliersResponse, SupplierPayload, EditSupplierPayload, Supplier } from "@ctypes/suppliers";
import { useQuery, UseQueryOptions, UseMutationOptions, useMutation } from "react-query";

export const useSuppliers = <T = {}>(params: T, options?: UseQueryOptions<SuppliersResponse, APIError>) => {
    return useQuery<SuppliersResponse, APIError>(
        ["suppliers", params],
        () => getSuppliersAPI<T>(params),
        options,
    );
};

// export const useSupplier = (id: string, options?: UseQueryOptions<Supplier, APIError>) => {
//     return useQuery<Supplier, APIError>(
//         ["supplier", id],
//         () => getSupplierByIdAPI(id),
//         options,
//     );
// };

export const useCreateSupplier = (options?: UseMutationOptions<Supplier, APIError, SupplierPayload>) => {
    return useMutation<Supplier, APIError, SupplierPayload>(
        (supplierData) => createSupplierAPI(supplierData),
        options,
    );
};


// export const useUpdateSupplier = (options?: UseMutationOptions<Supplier, APIError, EditSupplierPayload>) => {
//     return useMutation<Supplier, APIError, EditSupplierPayload>(
//         (supplierData) => updateSupplierAPI(supplierData),
//         options,
//     );
// };

