import { useQuery, UseQueryOptions } from "react-query";

import { ProductsFiltersPayload, ProductsResponse } from "@ctypes/products";
import { APIError } from "@ctypes/common";
import { getProductpAPI } from "@api/products";

export const useProductsList = (params?: ProductsFiltersPayload, options?: UseQueryOptions<ProductsResponse, APIError>) => {
	return useQuery<ProductsResponse, APIError>(
		["productList", params],
		() => {
			
			return getProductpAPI();
		},
		options,
	);
};

