import axios, { AxiosError } from "axios";
import { v4 as uuidv4 } from "uuid";
import { APIError } from "@ctypes/common";
import { EditProductPayload, Product, ProductPayload, ProductsFilters, ProductsResponse } from "@ctypes/products";

export const getProductsAPI = async (params?: ProductsFilters) => {
	try {
		const response = await axios.request<ProductsResponse>({
			url: "/product/all",
			method: "GET",
			params,
		});

		return response.data;
	} catch (error) {
		throw (error as AxiosError<APIError>).response?.data;
	}
};

export const createProductAPI = async (payload: ProductPayload) => {
	const formData = new FormData();

	formData.append("internalCode", payload.internalCode || uuidv4());
	formData.append("barCode", String(payload.barCode || uuidv4()));
	formData.append("codeSunat", String(payload.codeSunat || uuidv4()));
	formData.append("name", String(payload.name));
	formData.append("type", String(payload.type));
	formData.append("category", String(payload.category));
	formData.append("productionArea", String(payload.productionArea));
	formData.append("saleUnitPrice", String(payload.saleUnitPrice));
	formData.append("purchaseUnitPrice", String(payload.purchaseUnitPrice));
	formData.append("currencyTypes", String(payload.currencyTypes));
	formData.append("stock", String(payload.stock));
	formData.append("purchaseAffectationIGVTypes", String(payload.purchaseAffectationIGVTypes));
	formData.append("user", String(payload.user));

	if (payload.image) formData.append("image", payload.image);
	if (payload.unitTypes) formData.append("unitTypes", payload.unitTypes);
	if (payload.salesAffectationIGVTypes) formData.append("salesAffectationIGVTypes", payload.salesAffectationIGVTypes);
	if (payload.hasPlasticBagTaxes) formData.append("hasPlasticBagTaxes", String(payload.hasPlasticBagTaxes));
	if (payload.hasIGV) formData.append("hasIGV", String(payload.hasIGV));
	if (payload.isForProduction) formData.append("isForProduction", String(payload.isForProduction));
	if (payload.purchaseHasIGV) formData.append("purchaseHasIGV", String(payload.purchaseHasIGV));
	if (payload.establishments) formData.append("establishments", JSON.stringify(payload.establishments));

	formData.append("priceList", JSON.stringify(payload.priceList));
	formData.append("establishmentsPriceList", JSON.stringify(payload.establishmentsPriceList));
	formData.append("suppliesList", JSON.stringify(payload.suppliesList));
	formData.append("description", JSON.stringify(payload.description));

	try {
		const response = await axios.request<Product>({
			url: "/product/create",
			method: "POST",
			data: formData,
		});

		return response.data;
	} catch (error) {
		throw (error as AxiosError<APIError>).response?.data;
	}
};

export const editProductAPI = async ({ _id, ...payload }: EditProductPayload) => {
	const formData = new FormData();

	formData.append("internalCode", payload.internalCode || uuidv4());
	formData.append("barCode", String(payload.barCode || uuidv4()));
	formData.append("codeSunat", String(payload.codeSunat || uuidv4()));
	formData.append("name", String(payload.name));
	formData.append("type", String(payload.type));
	formData.append("category", String(payload.category));
	formData.append("productionArea", String(payload.productionArea));
	formData.append("description", String(payload.description));
	formData.append("saleUnitPrice", String(payload.saleUnitPrice));
	formData.append("purchaseUnitPrice", String(payload.purchaseUnitPrice));
	formData.append("currencyTypes", String(payload.currencyTypes));
	formData.append("purchaseAffectationIGVTypes", String(payload.purchaseAffectationIGVTypes));

	if (payload.image) formData.append("image", payload.image);
	if (payload.unitTypes) formData.append("unitTypes", payload.unitTypes);
	if (payload.salesAffectationIGVTypes) formData.append("salesAffectationIGVTypes", payload.salesAffectationIGVTypes);
	// if (payload.hasIGV)
	formData.append("hasIGV", String(payload.hasIGV));
	if (payload.isForProduction) formData.append("isForProduction", String(payload.isForProduction));
	if (payload.purchaseHasIGV) formData.append("purchaseHasIGV", String(payload.purchaseHasIGV));
	if (payload.hasPlasticBagTaxes) formData.append("hasPlasticBagTaxes", String(payload.hasPlasticBagTaxes));
	formData.append("isPromotion", String(payload.isPromotion));

	formData.append("priceList", JSON.stringify(payload.priceList));
	formData.append("establishments", JSON.stringify(payload.establishments));
	formData.append("establishmentsPriceList", JSON.stringify(payload.establishmentsPriceList));
	formData.append("suppliesList", JSON.stringify(payload.suppliesList));

	formData.append("has_image", String(payload.has_image));
	formData.append("public_id", String(payload.public_id));
	formData.append("user", String(payload.user));

	try {
		const response = await axios.request<Product>({
			url: `/product/update/${_id}`,
			method: "PATCH",
			data: formData,
		});
		return response.data;
	} catch (error) {
		throw (error as AxiosError<APIError>).response?.data;
	}
};

export const deleteProductAPI = async (id: string) => {
	try {
		await axios.request<void>({
			url: `/product/delete/${id}`,
			method: "DELETE",
		});
	} catch (error) {
		throw (error as AxiosError<APIError>).response?.data;
	}
};

export const removeProductAPI = async (id: string) => {
	try {
		await axios.request<void>({
			url: `/product/remove/${id}`,
			method: "PATCH",
		});
	} catch (error) {
		throw (error as AxiosError<APIError>).response?.data;
	}
};

export const setStatusProductAPI = async (id: string) => {
	try {
		const response = await axios.request<void>({
			url: `/product/setstatus/${id}`,
			method: "PUT",
		});
		return response.data;
	} catch (error) {
		throw (error as AxiosError<APIError>).response?.data;
	}
};

export const getProductAPI = async (params?: ProductsFilters) => {
	try {
		const response = await axios.request<ProductsResponse>({
			url: "/product/all",
			method: "GET",
			params,
		});

		return response.data;
	} catch (error) {
		throw (error as AxiosError<APIError>).response?.data;
	}
};
export const getProductpAPI = async () => {
	try {
		const response = await axios.request<ProductsResponse>({
			url: "/product/latest",
			method: "GET",
		});

		//console.log(response.data)
		return response.data;
	} catch (error) {
		throw (error as AxiosError<APIError>).response?.data;
	}
};

export const getProductpStock = async ({ productId, establishment }: { productId: string; establishment: string }) => {
	try {
		const response = await axios.request<ProductsResponse>({
			url: `/inventory/kardex/all?product=${productId}&establishment=${establishment}`,
			method: "GET",
		});

		//console.log(response.data)
		return response.data.documents[response.data.documents.length - 1];
	} catch (error) {
		throw (error as AxiosError<APIError>).response?.data;
	}
};

export const getProductspromoAPI = async (params?: ProductsFilters) => {
	try {
		const response = await axios.request<ProductsResponse>({
			url: "/product/all",
			method: "GET",
			params,
		});

		return response.data;
	} catch (error) {
		throw (error as AxiosError<APIError>).response?.data;
	}
};
