import { FC, useRef, useState } from "react";
import { Product, ProductsFiltersPayload } from "@ctypes/products";
import { useProducts } from "@hooks/queries/products";
import { Filters } from "@ctypes/common";

import { Container, Grid, Card, CardContent, CardMedia, Typography, Box } from '@mui/material';
import ProductsTable from "@components/products/ProductsTable";
import assets from "@assets/index";


// interface Product {
//   id: string;
//   image: string;
//   name: string;
//   description: string;
//   price: number;
// }
const ProductBoard: React.FC = () => {
  const [filters, setFilters] = useState<ProductsFiltersPayload>({
		offset: 0,
		limit: 100,
		filterBy: Filters.QUERY,
		query: "",
    type:'transformed',
    isForProduction: false,
    		
	});

	const { data: records, isLoading, refetch } = useProducts(filters);
   console.log('productos a visualizar  '+records?.documents)
  return (
    <Container>
  <Grid container spacing={3}>
    {records?.documents.map((product: Product) => (
      <Grid item xs={12} sm={6} md={4} lg={3} key={product._id}>
        <Card sx={{ height: '100%', backgroundColor: '#fff', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ height: 140, display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
            <CardMedia
              component="img"
              src={product.image ? product.image.url : assets.images.noImage}
              sx={{ 
                width: 'auto',
                height: '100%',
                maxWidth: '100%',
                objectFit: 'cover'
              }}
              alt={product.name}
            />
          </Box>
          <CardContent sx={{ flexGrow: 1 }}>
            <Typography gutterBottom variant="h5" component="div">
              {product.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {product.description}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              ${product.saleUnitPrice}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    ))}
  </Grid>
</Container>
  );
};

export default ProductBoard;
