import { useQuery, UseQueryOptions } from "react-query";

import { ProductsFiltersPayload, ProductsResponse } from "@ctypes/products";
import { APIError } from "@ctypes/common";
import { getProductsAPI } from "@api/products";

export const useProducts = (params?: ProductsFiltersPayload, options?: UseQueryOptions<ProductsResponse, APIError>) => {
	return useQuery<ProductsResponse, APIError>(
		["products", params],
		() => {
			let payload = { ...params } as any;
			if (params?.filterBy !== undefined) {
				const { query, filterBy, ...np } = params;
				payload = { ...np } as any;
				payload[filterBy] = query ?? "";
			}
			return getProductsAPI(payload);
		},
		options,
	);
};
