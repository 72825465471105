import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SuppliesList } from "@ctypes/common";

const initialState: SuppliesList[] = [];

export const productsBaseState = createSlice({
	name: "productsState",
	initialState,
	reducers: {
		addProduct: (state, action: PayloadAction<SuppliesList>) => {
			const productToAddOrUpdate = action.payload;
			const existingProductIndex = state.findIndex((data) => data.supply._id === productToAddOrUpdate.supply._id);

			if (existingProductIndex !== -1) {
				// Si el suministro ya existe, reemplázalo
				state[existingProductIndex] = productToAddOrUpdate;
			} else {
				// Si el suministro no existe, agrégalo a la lista
				state.push(productToAddOrUpdate);
			}
		},
		setProductOptional: (state, action: PayloadAction<string>) => {
			const ProductId = action.payload;
			const existingProduct = state.find((data) => data.supply._id === ProductId);

			if (existingProduct) {
				existingProduct.isOptional = !existingProduct.isOptional;
				existingProduct.isRequired = !existingProduct.isOptional;
			}
		},
		removeProduct: (state, action: PayloadAction<string>) => {
			return state.filter((data) => data.supply._id !== action.payload);
		},
		clearProducts: (state) => {
			state.splice(0, state.length);
		},
	},
});

export const { addProduct, setProductOptional, removeProduct, clearProducts } = productsBaseState.actions;

export default productsBaseState.reducer;
