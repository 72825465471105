import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SidebarState {
	open: boolean;
}

const initialState: SidebarState = {
	open: false,
};

const sidebarStateSlice = createSlice({
	name: "sidebarState",
	initialState,
	reducers: {
		setOpen: (state, action: PayloadAction<boolean>) => {
			state.open = action.payload;
		},
		toggleOpen: (state) => {
			state.open = !state.open;
		},
	},
});

export const { setOpen, toggleOpen } = sidebarStateSlice.actions;
export default sidebarStateSlice.reducer;
