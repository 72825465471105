import React from 'react';
import { Box, Typography } from '@mui/material';
import Carousel from 'react-material-ui-carousel';

interface BannerProps {
  data: Array<{
    to: string;
    img: string;
    title: string;
    description: string;
  }>;
}

const Banner: React.FC<BannerProps> = ({ data }) => {
  return (
    <Carousel interval={2000} indicators={true} navButtonsAlwaysVisible>
      {data.map((item, index) => (
        <Box key={index} component="div">
          <a href={item.to} style={{ textDecoration: 'none', color: 'inherit' }}>
            <img
              src={item.img}
              alt={item.title}
              style={{ width: '100%', height: '350px', objectFit: 'cover' }}
            />
            <Box
              sx={{
                position: 'absolute',
                bottom: '20px',
                left: '50%',
                transform: 'translateX(-50%)',
                color: 'white',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                padding: '10px',
                borderRadius: '10px',
                textAlign: 'center',
              }}
            >
              <Typography variant="h5">{item.title}</Typography>
              <Typography variant="body2">{item.description}</Typography>
            </Box>
          </a>
        </Box>
      ))}
    </Carousel>
  );
};

export default Banner;
