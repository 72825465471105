import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import AccountCircleIcon from '@mui/icons-material/AccountCircle'; // Importa el icono de cuenta


const HorizontalMenu: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{ backgroundColor: '#7b2031' }}>
        <Toolbar sx={{ justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img 
              src="../../images/banner/vazco.jpg" // Reemplaza con la ruta de tu imagen
              alt="Vazco"
              style={{ height: '40px',width:'100px', marginRight: '16px' }} // Ajusta el tamaño y el margen según sea necesario
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: isMobile ? 'flex-start' : 'center', flexGrow: 1, flexWrap: 'wrap' }}>
            <Button
              color="inherit"
              component="a"
              href="#section1"
              sx={{ fontSize: '1rem', color: 'white', fontFamily: 'Arial, sans-serif', margin: isMobile ? '0 8px' : '0 16px' }}
            >
              Inicio
            </Button>
            <Button
              color="inherit"
              component="a"
              href="#section2"
              sx={{ fontSize: '1rem', color: 'white', fontFamily: 'Arial, sans-serif', margin: isMobile ? '0 8px' : '0 16px' }}
            >
              Nuevos Productos
            </Button>
            <Button
              color="inherit"
              component="a"
              href="#section3"
              sx={{ fontSize: '1rem', color: 'white', fontFamily: 'Arial, sans-serif', margin: isMobile ? '0 8px' : '0 16px' }}
            >
              Nuestra Empresa
            </Button>
            <IconButton
              component={Link}
              to="/auth"
              sx={{ color: 'white', margin: isMobile ? '0 8px' : '0 16px', fontSize: '4rem' }} // Ajusta el tamaño del icono
            >
              <AccountCircleIcon fontSize="large" /> {/* Ajusta el tamaño del icono */}
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default HorizontalMenu;
