import React from 'react';
import { Alert, Fade, AlertColor } from '@mui/material';

interface CenteredSnackbarProps {
  open: boolean;
  message: string;
  severity: AlertColor;
  onClose: () => void;
}

const CenteredSnackbar: React.FC<CenteredSnackbarProps> = ({ open, message, severity, onClose }) => {
  return (
    <Fade in={open}>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9999,
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fondo semitransparente opcional
        }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={onClose}
          severity={severity}
          sx={{
            boxShadow: 24,
            minWidth: '300px',
          }}
        >
          {message}
        </Alert>
      </div>
    </Fade>
  );
};

export default CenteredSnackbar;