import axios, { AxiosError } from "axios";
import { APIError } from "@ctypes/common";
import { Supplier, SupplierPayload,SuppliersResponse,EditSupplierPayload} from "@ctypes/suppliers";
export const getSuppliersAPI = async <T = {}>(params: T) => {
	try {
		const response = await axios.request<SuppliersResponse>({
			url: "/suppliers/all",
			method: "GET",
			params,
		});

		return response.data;
	} catch (error) {
		throw (error as AxiosError<APIError>).response?.data;
	}
};

export const createSupplierAPI = async ({ _id: _, ...payload }: SupplierPayload) => {
	try {
		const response = await axios.request<Supplier>({
			url: "suppliers/crear",
			method: "POST",
			data: payload,
		});

		return response.data;
	} catch (error) {
		throw (error as AxiosError<APIError>).response?.data;
	}
};

// export const updateSupplierAPI = async ({ _id, ...payload }: EditSupplierPayload) => {
// 	try {
// 		const response = await axios.request<Supplier>({
// 			url: `suppliers/update/${_id}`,
// 			method: "PUT",
// 			data: payload,
// 		});
// 		return response.data;
// 	} catch (error) {
// 		throw (error as AxiosError<APIError>).response?.data;
// 	}
// };

export const updateSupplierAPI = async (id: string | number, supplierData: SupplierPayload) => {
	const response = await axios.put(`/suppliers/update/${id}`, supplierData);
	return response.data;
  };