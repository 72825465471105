import React, { useState, useEffect } from 'react';
import "../../assets/css/slider.css";
import { motion } from 'framer-motion';
import { ImageInfo } from '../../types/types';
import { Product } from '@ctypes/products';
import assets from '@assets/index';
import { useProductsPromotion } from '../../hooks/queries/productsPromotion';
import { Filters } from '../../types/common';
import { Modal, Box, Typography, Button } from '@mui/material';
import { useProductsList } from '@hooks/queries/productslista';

const SliderPromotionNew: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<ImageInfo | null>(null);
  const [widthOfImage, setWidthOfImage] = useState<number>(0);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [marginBetweenImages, setMarginBetweenImages] = useState<number>(0);

  const [filters, setFilters] = useState({
    offset: 0,
    limit: 10,
    filterBy: Filters.QUERY,
    query: "",
    isForProduction: false,
    isPromotion: true,
    type:'transformed'
  });

 // const { data: records } = useProductsPromotion(filters);
  const { data: records, isLoading, refetch } = useProductsList();

  useEffect(() => {
    if (records && records.documents.length > 0) {
      const getImageWidth = async () => {
        const img = new Image();
        img.src = records.documents[0].image ? records.documents[0].image.url : assets.images.noImage;
        img.onload = () => {
          setWidthOfImage(img.width);
        };
      };

      const getMarginBetweenImages = () => {
        const imageStyles = window.getComputedStyle(document.querySelector('.item img') as Element);
        const marginValue = imageStyles.marginRight;
        const parsedMargin = parseFloat(marginValue.replace('px', ''));
        setMarginBetweenImages(parsedMargin);
      };

      getImageWidth();
      getMarginBetweenImages();
    }
  }, [records]);

  const handleImageClick = (product: Product) => {
   // setSelectedImage(product.image);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedImage(null);
  };

  const totalWidth = records ? records.documents.length * (widthOfImage + marginBetweenImages) : 0;

  const nextSlide = () => {
    if (records && records.documents.length > 0) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % records.documents.length);
    }
  };

  const prevSlide = () => {
    if (records && records.documents.length > 0) {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + records.documents.length) % records.documents.length);
    }
  };

  if (!records) {
    return <p>Loading...</p>;
  }

  return (
    <div className='slider-container' style={{ border: '0px solid #000', padding: '10px' }}>
  <div className='slider-wrapper' style={{ border: '0px solid #ddd' }}>
    <motion.div className='slider' style={{ width: totalWidth, border: '0px solid #ccc' }} drag='x' dragConstraints={{ right: 0, left: -totalWidth }}>
      {records.documents.map((product, index) => (
        <motion.div key={index} className={`item ${index === currentIndex ? 'active' : ''}`} onClick={() => handleImageClick(product)} style={{ border: '1px solid #bbb' }}>
          <div className="badge">Nuevo</div>
          <img src={product.image ? product.image.url : assets.images.noImage} alt={product.name} className="fixed-size" style={{ border: '2px solid #aaa' }} />
          <div className="info">
            <h3>{product.name}</h3>
            <p>{product.description.replace(/['"]+/g, '')}</p>
            <Typography variant="h6" color="error" sx={{ fontSize: '1.5rem' }}>
              ${product.saleUnitPrice}
            </Typography>
          </div>
        </motion.div>
      ))}
    </motion.div>
  </div>
</div>
      
  );
};

export default SliderPromotionNew;
