import { configureStore } from "@reduxjs/toolkit";
import suppliesStateSlice from "@redux/features/suppliesStateSlice";
import ordersStateSlice from "./features/ordersStateSlice";
import appStateSlice from "./features/appStateSlice";
import sidebarStateSlice from "./features/sidebarStateSlice";
import productsBaseState from "./features/productState";

export const store = configureStore({
	reducer: {
		appState: appStateSlice,
		suppliesState: suppliesStateSlice,
		ordersState: ordersStateSlice,
		sidebarState: sidebarStateSlice,
		productState: productsBaseState,
	},
});

export type RootState = ReturnType<typeof store.getState>;
