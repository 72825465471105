import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Orders from '@pages/orders/Orders';
import ProductBoard from './ProductBoard';
import { Typography } from '@mui/material';

const CustomButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    onClick(); // Llama a la función de clic proporcionada como prop
    setOpen(true); // Abre la ventana modal al hacer clic
  };

  const handleClose = () => {
    setOpen(false); // Cierra la ventana modal
  };

  return (
    <>
      <Typography
  component="label"
  sx={{
    display: 'inline-block',
    width: '20%',
    cursor: 'pointer',
    padding: '8px 16px',
    //backgroundColor: '#5cffb0',
    color: 'black',
    borderRadius: '4px',
    textAlign: 'center',
    textDecoration: 'none',
    fontSize: '1.5em',
    '&:hover': {
      //backgroundColor: '#0056b3',
      color: 'red',
    },
  }}
  onClick={handleClick}
>
  Ver todos los productos {'>>'}
</Typography>

      {/* Ventana modal */}
      <Dialog open={open} onClose={handleClose}
      sx={{
        '& .MuiDialog-paper': {
          minWidth: '80vw', // Ancho mínimo
          minHeight: '80vh', // Altura mínima
        },
      }}>
        <DialogTitle>Productos en stock</DialogTitle>
        <DialogContent>
        <ProductBoard/>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomButton;
