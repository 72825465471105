import React, { useState } from 'react'
import { Filters } from '../../types/common';

import { useProductsList } from '../../hooks/queries/productslista';
import { useProductsPromotion } from '../../hooks/queries/productsPromotion'
import { ProductsFiltersPayload } from '../../types/products';


import HorizontalMenu from '@components/principal/HorizontalMenu';
import Banner from '../../components/principal/Banner';
import { Container, Row, Col } from 'react-bootstrap';
import Slider from '@components/principal/Slider';
import Footer from '@components/principal/Footer';
import { Modal, Box, Typography, Button } from '@mui/material';
import SliderPromotion from '@components/principal/SliderPromotion';
import CustomButton from '@components/principal/CustomButton';
import SliderPromotionNew from '@components/principal/SliderPromotionNew';



export const Principal = () => {


  const { data: records, isLoading, refetch } = useProductsList();

  const [filters, setFilters] = useState<ProductsFiltersPayload>({
    offset: 0,
    limit: 10,
    filterBy: Filters.QUERY,
    query: "",
    isForProduction: false,
    isPromotion: true
  });
  // const { data: dataProducts } = useProductsPromotion(filters);
  // console.log("debería mostrar promocion: ",dataProducts?.documents )
  // const data = JSON.stringify(dataProducts?.documents, null, 2);

  const handleClick = () => {
    console.log('Botón clickeado desde el componente padre');
    // Aquí puedes agregar lógica adicional que se ejecutará cuando se haga clic en el botón
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (!records) {
    return <p>No data available.</p>;
  }

  const datos = {
    banner: [
      {
        to: "/link",
        img: "../../images/banner/tres.jpg",
        title: "First slide label",
        description: "Nulla vitae elit libero, a pharetra augue mollis interdum",
      },
      {
        to: "/link",
        img: "../../images/banner/fogata.jpg",
        title: "Second slide label",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        to: "/link",
        img: "../../images/banner/dos.jpg",
        title: "Third slide label",
        description: " Praesent commodo cursus magna, vel scelerisque nisl.",
      },
      {
        to: "/link",
        img: "../../images/banner/uno.jpg",
        title: "Third slide label",
        description: " Praesent commodo cursus magna, vel scelerisque nisl.",
      },
    ],
    iconProducts: [
      {
        imagen: "../../images/banner/bebida3.jpg",
        description: "bedida refrescante",
        name: "agua de limon",

      },
      {
        imagen: "../../images/banner/bebida2.jpg",
        description: "bedida refrescante",
        name: "agua de limon",
      },
      {
        imagen: "../../images/banner/bebida3.jpg",
        description: "bedida refrescante",
        name: "agua de limon",
      },
      {
        imagen: "../../images/banner/bebida4.jpg",
        description: "bedida refrescante",
        name: "agua de limon",
      },
      {
        imagen: "../../images/banner/bebida5.jpg",
        description: "bedida refrescante",
        name: "agua de limon",
      },
      {
        imagen: "../../images/banner/bebida6.jpg",
        description: "bedida refrescante",
        name: "agua de limon",
      },

    ]
  }

  return (
    <Container fluid style={{ marginTop: '30px', width: '80%', maxWidth: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
      <Row>
        <Col xs={12}>
          <HorizontalMenu />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={10} lg={8} className="mx-auto">
          <div id='section1'>
            <Banner data={datos.banner} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={10}>
           <CustomButton onClick={handleClick} />
 </Col>
      </Row>
      <div id='section2' >
          <Row>
          <Col md={10}>
            <div className="custom-bg bg-gradient p-3 text-center mb-3 custom-banner">
              <h4 className="m-0">Productos Nuevos</h4>
            </div>
            <SliderPromotionNew />
          </Col>
        </Row>
           </div>

      

      <div id='section3' >
        <Row>
          <Col md={10}>
            <div className="custom-bg bg-gradient p-3 text-center mb-3 custom-banner">
              <h4 className="m-0">Productos en Promoción</h4>
            </div>
            <SliderPromotion />
          </Col>
        </Row>
      </div>



      <Footer />

    </Container>

  )
}