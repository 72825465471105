import { FC, useState, useEffect } from "react";
import { useFormik } from "formik";
import {
	Button,
	Checkbox,
	FormControlLabel,
	FormGroup,
	IconButton,
	InputAdornment,
	Stack,
	TextField,
} from "@mui/material";

import { LoginFormPayload } from "@ctypes/auth";
import { AuthSchema } from "@schemas/auth/auth.schema";
import ImageSVG from "@components/logo/ImageSGV";
import assets from "@assets/index";

type LoginFormProps = {
	onSubmit: (payload: LoginFormPayload) => void;
	submitting?: boolean;
	setRememberMe(p: boolean): void;
	blockingTime: number;
};

const LoginForm: FC<LoginFormProps> = ({ onSubmit, submitting, setRememberMe, blockingTime }) => {
	const [timeRemaining, setTimeRemaining] = useState(0);

	const [showPassword, setShowPassword] = useState(false);
	const [textIsNotEmpty, setTextIsNotEmpty] = useState(true);

	const { handleChange, errors, values, handleSubmit } = useFormik<LoginFormPayload>({
		initialValues: {
			email: "",
			password: "",
			rememberMe: true,
		},
		onSubmit: async (payload) => {
			onSubmit(payload);
		},

		validationSchema: AuthSchema,
		validateOnChange: false,
	});

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	useEffect(() => {
		setRememberMe(values?.rememberMe || false);
	}, []);

	useEffect(() => {
		// Actualizar timeRemaining cuando blockingTime cambie
		setTimeRemaining(blockingTime * 60);
	}, [blockingTime]);

	useEffect(() => {
		if (blockingTime > 0) {
			const intervalId = setInterval(() => {
				setTimeRemaining((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
			}, 1000);

			return () => clearInterval(intervalId);
		} else {
			// Si blockingTime es 0, reiniciar el cronómetro
			setTimeRemaining(blockingTime * 60);
		}
	}, [blockingTime]);

	const getButtonText = () => {
		if (timeRemaining > 0) {
			const minutes = Math.floor(timeRemaining / 60);
			const seconds = timeRemaining % 60;
			return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
		} else {
			return "INGRESAR";
		}
	};

	useEffect(() => {
		if (values.email.length > 0 && values.password.length > 0) {
			setTextIsNotEmpty(false);
		} else {
			setTextIsNotEmpty(true);
		}
	}, [values.email, values.password]);

	return (
		<Stack component="form" justifyContent="stretch" spacing={2} onSubmit={handleSubmit}>
			<TextField
				name="email"
				type="email"
				label="Correo electrónico"
				onChange={handleChange}
				value={values.email}
				error={!!errors.email}
				helperText={errors.email}
				autoComplete="false"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<ImageSVG image={assets.images.arroba} />
						</InputAdornment>
					),
				}}
			/>
			<TextField
				label="Contraseña"
				name="password"
				type={showPassword ? "text" : "password"}
				value={values.password}
				onChange={handleChange}
				error={!!errors.password}
				helperText={errors.password}
				autoComplete="false"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<ImageSVG image={assets.images.contrasena} />
						</InputAdornment>
					),
					endAdornment: (
						<InputAdornment position="end">
							<IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
								{showPassword ? <ImageSVG image={assets.images.noVisible} /> : <ImageSVG image={assets.images.visible} />}
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							checked={values.rememberMe}
							onChange={(e) => {
								setRememberMe(e.target.checked);
								handleChange(e);
							}}
							name="rememberMe"
						/>
					}
					label="Mantener sesión iniciada"
				/>
			</FormGroup>
			<Button
				type="submit"
				variant="contained"
				sx={{ fontSize: "15px" }}
				disabled={submitting || timeRemaining > 0 || textIsNotEmpty}
			>
				{getButtonText()}
			</Button>
		</Stack>
	);
};

export default LoginForm;
