import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
    return (
        <footer>
            <Box sx={{ bgcolor: 'white', color: 'white', py: 4 }}>
                <Grid container justifyContent="center" spacing={2}>
                    {/* Sección 1: Empresa y Políticas */}
                    <Grid item md={3} xs={12}>
                        <Typography variant="h6" gutterBottom color="text.primary" style={{ textAlign: 'center' }}>
                            Empresa y Políticas
                        </Typography>
                        <Divider />
                        <Typography variant="body2" sx={{ mt: 2 }}  color="text.primary">
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry. Lorem Ipsum has been the industry's standard dummy text
                            ever since the 1500s, when an unknown printer took a galley of
                            type and scrambled it to make a type specimen book.
                        </Typography>
                    </Grid>

                    {/* Sección 2: Enlaces de interés */}
                    <Grid item md={3} xs={12}>
                        <Typography variant="h6" gutterBottom color="text.primary" style={{ textAlign: 'center' }}>
                            Enlaces de interés
                        </Typography>
                        <Divider />
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mt: 2 }}>
                            <Link to="/" className="text-white text-decoration-none" >
                                <IconButton color="inherit">
                                    <i className="bi bi-briefcase text-warning"></i>
                                </IconButton>
                                Partner with us
                            </Link>
                            <Link to="/" className="text-white text-decoration-none" >
                                <IconButton color="inherit">
                                    <i className="bi bi-badge-ad text-info"></i>
                                </IconButton>
                                Advertise
                            </Link>
                            <Link to="/" className="text-white text-decoration-none" >
                                <IconButton color="inherit">
                                    <i className="bi bi-gift"></i>
                                </IconButton>
                                Gift
                            </Link>
                        </Box>
                    </Grid>

                    {/* Sección 3: Información de contacto y pago */}
                    <Grid item md={3} xs={12}>
                        <Typography variant="h6" gutterBottom color="text.primary" style={{ textAlign: 'center' }}>
                            Contacto y Pago
                        </Typography>
                        <Divider />
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                            <Typography variant="body2" sx={{ flexGrow: 1 }}>
                                © 2009-{new Date().getFullYear()}
                            </Typography>
                            <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                <img
                                    src="../../images/payment/american_express.webp"
                                    width="32"
                                    alt="American Express"
                                    className="me-2"
                                />
                                <img
                                    src="../../images/payment/maestro.webp"
                                    width="32"
                                    alt="Maestro"
                                    className="me-2"
                                />
                                <img
                                    src="../../images/payment/netbanking.webp"
                                    width="32"
                                    alt="Net Banking"
                                    className="me-2"
                                />
                                <img
                                    src="../../images/payment/paypal.webp"
                                    width="32"
                                    alt="Paypal"
                                    className="me-2"
                                />
                                <img
                                    src="../../images/payment/rupay.webp"
                                    width="32"
                                    alt="Rupay"
                                    className="me-2"
                                />
                                <img
                                    src="../../images/payment/upi.webp"
                                    width="32"
                                    alt="UPI"
                                    className="me-2"
                                />
                                <img
                                    src="../../images/payment/visa.webp"
                                    width="32"
                                    alt="Visa"
                                    className="me-2"
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </footer>
    );
};

export default Footer;
